<template>
    <div>
        <div class="weui-cells__title">已完成</div>
        <div class="weui-panel weui-panel_access">
            <div class="weui-panel__bd">
                <div
                    v-for="(item, index) in food_list"
                    v-bind:key="index"
                    class="weui-media-box weui-media-box_appmsg"
                    v-if="food_list.length > 0"
                >
                    <div class="weui-media-box__hd weui-media-box__hd_in-appmsg">
                        <img class="weui-media-box__thumb" :src="item.fpic" />
                    </div>
                    <div class="weui-media-box__bd weui-media-box__bd_in-appmsg">
                        <div class="weui-media-box__title">
                            {{ item.fname }}
                        </div>
                        <div class="weui-media-box__info" style="margin-top: 15rpx; padding-bottom: 0">
                            <div class="weui-media-box__info__meta">x{{ item.count }}</div>
                            <div class="weui-media-box__info__meta" style="float: right; color: red; padding-right: 0">
                                ${{ item.fprice * item.count }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 0">
                <mt-cell title="合计">
                    <span style="color: red; font-size: 14px">$4.99</span>
                </mt-cell>
            </div>
        </div>
        <div style="margin-top: 10px">
            <mt-cell title="订单号" v-model="order_data.oid" />
            <mt-cell title="订单时间" v-model="order_data.create_time" />
            <mt-cell title="订单类型" v-model="order_data.otype" />
            <mt-cell title="联系人" v-model="order_data.name" />
            <mt-cell title="联系电话" v-model="order_data.tel" />
            <mt-cell title="送餐地址" v-model="order_data.address" v-if="order_data.address" />
            <mt-cell title="支付方式" value="取餐付款" />
            <mt-cell title="支付方式" v-model="order_data.omessage" v-if="order_data.omessage" />
        </div>
        <div v-if="stel" style="padding: 5px 10px">
            商户联系方式
            <a href="javascript:;" style="color: #09bb07">{{ stel }}</a>
        </div>
    </div>
</template>
<script>
import '../assets/style/orderdetail.css';
import Vue from 'vue';
import { Cell } from 'mint-ui';
import { mapState, mapActions } from 'vuex';

Vue.component(Cell.name, Cell);

export default {
    name: 'orderdetail',
    data() {
        return {
            stel: '(718)888-9068',
            oid: this.$route.query.oid || ''
        };
    },
    computed: {
        ...mapState({
            order_data: (state) => state.orderdetail.order_data,
            food_list: (state) => state.orderdetail.foods
        })
    },
    created() {
        this.initData(this.oid);
    },
    methods: {
        ...mapActions({
            initData: 'orderdetail/initData'
        })
    }
};
</script>
